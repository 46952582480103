<template>
	<div class="grid">
		<div class="col-12">

			<div class="card">
				DatavalidationController (mode=0) {{perf0}}
				<DataTable :value="logs" class="p-datatable-sm" 
					v-model:filters="filters1" responsiveLayout="scroll" showGridlines stripedRows
          :paginator="true" :rows="1"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[1,25,50,75,100]"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
					:globalFilterFields="['file_name','last_line']"
					selectionMode="single">
					<template #header>
						<span class="p-input-icon-left">
							<i class="pi pi-search" />
							<InputText v-model="filters1['global'].value" placeholder="キーワード" />
						</span>
					</template>
					<Column field="file_name" header="ログ" :sortable="true"></Column>
					<Column field="error_cnt" header="エラー" :sortable="true"></Column>
          <Column field="last_line" header="最終行" :sortable="true"></Column>
				</DataTable>
			</div>

      <Accordion :activeIndex="0" class="accordion-r">
        <AccordionTab header="R">

          <p>
            <template v-for="(rela, index) in relas" :key="index">
              <Chip class="chip-r" :class="getChipClass(index)">
                <a href="#" @click.prevent.stop="active1 = index;onRelaTabClick(index)">
                  <font :class="getChipTextClass(index)">{{rela.desc}}</font>
                </a>
              </Chip>
            </template>
          </p>

          <Calendar v-model="calValues" selectionMode="range" dateFormat="yy/mm/dd" showIcon />
          <TabView lazy class="tabview-custom" @tab-click="onRelaTabClick($event.index)" v-model:activeIndex="active1" scrollable>
            <template v-for="rela in relas" :key="rela.code">
              <TabPanel>
                <template #header>
                  <span>{{rela.name}}</span>
                </template>
                DatavalidationController (mode=1) {{perf1}}
                <DataTable :value="relastats" class="p-datatable-sm" 
                  responsiveLayout="scroll" showGridlines stripedRows
                  selectionMode="single"
                  :paginator="true" :rows="10"
                  paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[10,25,50,75,100]"
                  currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"         
                  :loading="loading"
                  v-model:filters="filters_r" filterDisplay="row">
                  <template #header>
                    [{{relastats_rtypename}}] r_total: {{relastats ? relastats.length : 0 }}, 1dc_total: {{relastats_v1dc_total}}
                  </template>
                  <Column field="rdate" header="rdate" filterField="rdate" filterMatchMode="contains" ref="rdate" :sortable="true" :style="{'min-width': '10rem'}">
                    <template #filter="{filterModel,filterCallback}">
                      <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="p-column-filter" placeholder="日付" />
                    </template>
                  </Column>     
                  <Column field="rtime" header="rtime" :sortable="true"></Column>              
                  <Column field="rcd" header="rcd" filterField="rcd" filterMatchMode="contains" ref="rcd" :sortable="true" :style="{'min-width': '10rem'}">
                    <template #filter="{filterModel,filterCallback}">
                      <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="p-column-filter" placeholder="コード" />
                    </template>
                  </Column>                
                  <Column field="rname" header="rname" :sortable="true"></Column>
                  <Column field="ridx" header="ridx" :sortable="true"></Column>
                  <Column field="v1d_count" header="1dc" :sortable="true"></Column>
                  <Column field="v1d_mean" header="1dm" :sortable="true">
                    <template #body="slotProps">
                      <div :class="priceClass_1dm(slotProps.data)">
                        {{slotProps.data.v1d_mean}}
                      </div>
                    </template>
                  </Column>                
                  <Column field="v1d_volume_mean" header="1dvm" :sortable="true"></Column>
                  <Column field="v1d_std" header="1ds" :sortable="true"></Column>
                  <Column field="v2d_mean" header="2dm" :sortable="true">
                    <template #body="slotProps">
                      <div :class="priceClass_2dm(slotProps.data)">
                        {{slotProps.data.v2d_mean}}
                      </div>
                    </template>                  
                  </Column>
                  <Column field="v3d_mean" header="3dm" :sortable="true">
                    <template #body="slotProps">
                      <div :class="priceClass_3dm(slotProps.data)">
                        {{slotProps.data.v3d_mean}}
                      </div>
                    </template>                  
                  </Column>
                  <Column field="v5d_mean" header="5dm" :sortable="true">
                    <template #body="slotProps">
                      <div :class="priceClass_5dm(slotProps.data)">
                        {{slotProps.data.v5d_mean}}
                      </div>
                    </template>                  
                  </Column>            
                </DataTable>
              </TabPanel>
            </template>
          </TabView>

        </AccordionTab>
      </Accordion>

      <Accordion :activeIndex="0" class="accordion-r">
        <AccordionTab header="News Sentiment">        

          <Calendar v-model="calValues2" selectionMode="range" dateFormat="yy/mm/dd" showIcon />        
          <TabView lazy class="tabview-custom" @tab-click="onSentiTabClick($event)" v-model:activeIndex="active2" scrollable>
            <template v-for="sentiment in sentiments" :key="sentiment.code">
              <TabPanel>
                <template #header>
                  <span>{{sentiment.name}}</span>
                </template>
                DatavalidationController (mode=2) {{perf2}}
                <DataTable :value="newstrends" class="p-datatable-sm" 
                  responsiveLayout="scroll" showGridlines stripedRows
                  selectionMode="single"
                  :paginator="true" :rows="10"
                  paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[10,25,50,75,100]"
                  currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"         
                  :loading="loading2"
                  v-model:filters="filters_newstrend" filterDisplay="row">
                  <Column field="trend_date" header="日付" filterField="trend_date" filterMatchMode="contains" ref="trend_date" :sortable="true" :style="{'min-width': '10rem'}">
                    <template #filter="{filterModel,filterCallback}">
                      <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="p-column-filter" placeholder="日付" />
                    </template>
                  </Column>     
                  <Column field="trend_timestamp" header="時刻"></Column>                      
                  <Column field="zairyo_m" header="材料_m" :sortable="true">
                    <template #body="slotProps">
                      <div :class="priceClass_zairyo(slotProps.data)">
                        {{slotProps.data.zairyo_m}}
                      </div>
                    </template>
                  </Column>                
                  <Column field="zairyo_c" header="材料_c" :sortable="true"></Column>
                  <Column field="teku_m" header="テク_m" :sortable="true">
                    <template #body="slotProps">
                      <div :class="priceClass_teku(slotProps.data)">
                        {{slotProps.data.teku_m}}
                      </div>
                    </template>
                  </Column>                     
                  <Column field="teku_c" header="テク_c" :sortable="true"></Column>
                  <Column field="tokusyuu_m" header="特集_m" :sortable="true">
                    <template #body="slotProps">
                      <div :class="priceClass_tokusyuu(slotProps.data)">
                        {{slotProps.data.tokusyuu_m}}
                      </div>
                    </template>
                  </Column>                    
                  <Column field="tokusyuu_c" header="特集_c" :sortable="true"></Column>     
                  <Column field="syuusei_m" header="修正_m" :sortable="true">
                    <template #body="slotProps">
                      <div :class="priceClass_syuusei(slotProps.data)">
                        {{slotProps.data.syuusei_m}}
                      </div>
                    </template>                
                  </Column> 
                  <Column field="syuusei_c" header="修正_c" :sortable="true"></Column> 
                  <Column field="kaijijohou_m" header="開示情報_m" :sortable="true">
                    <template #body="slotProps">
                      <div :class="priceClass_kaijijohou(slotProps.data)">
                        {{slotProps.data.kaijijohou_m}}
                      </div>
                    </template>                 
                  </Column>
                  <Column field="kaijijohou_c" header="開示情報_c" :sortable="true"></Column>
                  <Column field="kessan_m" header="決算_m" :sortable="true">
                    <template #body="slotProps">
                      <div :class="priceClass_kessan(slotProps.data)">
                        {{slotProps.data.kessan_m}}
                      </div>
                    </template>                     
                  </Column>
                  <Column field="kessan_c" header="決算_c" :sortable="true"></Column>
                  <Column field="chuumoku_m" header="注目_m" :sortable="true">
                    <template #body="slotProps">
                      <div :class="priceClass_chuumoku(slotProps.data)">
                        {{slotProps.data.chuumoku_m}}
                      </div>
                    </template>                 
                  </Column>
                  <Column field="chuumoku_c" header="注目_c" :sortable="true"></Column>
                  <Column field="sikyou_m" header="市況_m" :sortable="true">
                    <template #body="slotProps">
                      <div :class="priceClass_sikyou(slotProps.data)">
                        {{slotProps.data.sikyou_m}}
                      </div>
                    </template>                   
                  </Column>
                  <Column field="sikyou_c" header="市況_c" :sortable="true"></Column>
                  <Column field="gopa_m" header="５％_m" :sortable="true">
                    <template #body="slotProps">
                      <div :class="priceClass_gopa(slotProps.data)">
                        {{slotProps.data.gopa_m}}
                      </div>
                    </template>                     
                  </Column>
                  <Column field="gopa_c" header="５％_c" :sortable="true"></Column>
                  <Column field="other_m" header="Other_m" :sortable="true">
                    <template #body="slotProps">
                      <div :class="priceClass_other(slotProps.data)">
                        {{slotProps.data.other_m}}
                      </div>
                    </template>                   
                  </Column>
                  <Column field="other_c" header="Other_c" :sortable="true"></Column>
                </DataTable>
              </TabPanel>
            </template>
          </TabView>

        </AccordionTab>
      </Accordion>

		</div>
	</div>
</template>

<script>
import { FilterMatchMode } from 'primevue/api';
import datavalidationService from '@/service/DatavalidationService';
import CommonService from '../service/CommonService';
// import Calendar from 'primevue/calendar';
export default {
	data() {
		return {
      active1: 0,
      active2: 0,
      newstrends: null,
      common: null,
      loading: false,      
      loading2: false,       
      calValues: [],
      calValues2: [],
			logs: null,     
			filters1: {},
      filters_r: {
        'rdate': {value: '', matchMode: 'contains'},
        'rcd': {value: '', matchMode: 'contains'},
      },
      filters_newstrend: {
        'trend_date': {value: '', matchMode: 'contains'}
      },
			perf0: null,
			perf1: null,      
			perf2: null,      
      currentRelaID: null,
      relastats: null,
      relastats_v1dc_total: null,
      relastats_rtypename: null,
      relas: [
        {name: 'R1', code: 'r1', desc: 'R1 業種'},
        {name: 'R2', code: 'r2', desc: 'R2 テーマ'},
        {name: 'R3', code: 'r3', desc: 'R3 市場'},
        {name: 'R4', code: 'r4', desc: 'R4 規模'},
        {name: 'R5', code: 'r5', desc: 'R5 PER'},
        {name: 'R6', code: 'r6', desc: 'R6 PBR'},
        {name: 'R7', code: 'r7', desc: 'R7 利回り'},
        {name: 'R8', code: 'r8', desc: 'R8 信用倍率'},
        {name: 'R9', code: 'r9', desc: 'R9 時価総額'},
        {name: 'R10', code: 'r10', desc: 'R10 貸借'},
        {name: 'R11', code: 'r11', desc: 'R11 比較銘柄優位性'},
        {name: 'R12', code: 'r12', desc: 'R12 決算日'},
        {name: 'R13', code: 'r13', desc: 'R13 株価連続性'},
        {name: 'R14', code: 'r14', desc: 'R14 株価前日比'},
        {name: 'R15', code: 'r15', desc: 'R15 出来高前日比'},
        {name: 'R16', code: 'r16', desc: 'R16 MA5乖離率'},
        {name: 'R17', code: 'r17', desc: 'R17 MA10乖離率'},
        {name: 'R18', code: 'r18', desc: 'R18 MA25乖離率'},
        {name: 'R19', code: 'r19', desc: 'R19 MA50乖離率'},
        {name: 'R20', code: 'r20', desc: 'R20 MA75乖離率'},
        {name: 'R21', code: 'r21', desc: 'R21 市場優位性'},
        {name: 'R22', code: 'r22', desc: 'R22 ニュース'},
        {name: 'R23', code: 'r23', desc: 'R23 GU'},
        {name: 'R24', code: 'r24', desc: 'R24 ボラ'}
      ],
      sentiments: [
        {name: 'Negative', code: 'N'},
        {name: 'Positive', code: 'P'},
        {name: 'Both', code: 'B'},
        {name: 'Unknown', code: 'U'}
      ],      
		}
	},
	datavalidationService: null,
	created() {
		this.datavalidationService = new datavalidationService();
		this.initFilters1();
    this.common = new CommonService();
	},
	mounted() {
    this.getLogFilesStatus();
    this.getRelastats(0);
    this.getNewstrend(0);
	},
	methods: {
    getLogFilesStatus() {
      this.datavalidationService.getAll(0, null, null, null)
        .then(d => {
          this.logs = d.data;
          this.perf0 = d.perf;
        });    
    },
    getRelastats(tabIndex) {
      let date1 = '';
      let date2 = '';
      if(this.calValues) { 
        date1 = this.common.getYMD(this.calValues[0]);
        date2 = this.common.getYMD(this.calValues[1]);
      }
      // if (this.currentRelaID != tabIndex) {
      //   this.datavalidationService.getAll(1, this.relas[tabIndex].code, date1, date2)
      //   .then(d => {
      //     this.relastats = d.data;
      //     this.perf1 = d.perf;
      //     this.loading = false;
      //   });
      //   this.currentRelaID = tabIndex;
      // }
      this.loading = true;
      this.datavalidationService.getAll(1, this.relas[tabIndex].code, date1, date2)
      .then(d => {
        this.relastats = d.data.datasets;
        this.relastats_v1dc_total = d.data.v1dc_total;
        this.relastats_rtypename = d.data.rtypename;
        this.perf1 = d.perf;
        this.loading = false;
      });
    },
    getNewstrend(tabIndex) {
      let date1 = '';
      let date2 = '';
      if(this.calValues2) { 
        date1 = this.common.getYMD(this.calValues2[0]);
        date2 = this.common.getYMD(this.calValues2[1]);
      }
      this.loading2 = true;
      this.datavalidationService.getAll(2, this.sentiments[tabIndex].code, date1, date2)
      .then(d => {
        this.newstrends = d.data;
        this.perf2 = d.perf;
        this.loading2 = false;
      });
    },    
		initFilters1() {
			this.filters1 = {
				'global': {value: null, matchMode: FilterMatchMode.CONTAINS}
			}
		},
    onRelaTabClick(index){
      this.getRelastats(index);
    },
    onSentiTabClick(event){
      this.getNewstrend(event.index);
    },    
    priceClass_1dm(data) {
      return [
        {
          'positive': data.v1d_mean > 0,
          'negative': data.v1d_mean < 0,
          'neutral': data.v1d_mean == 0,
        }
      ];
    },
    priceClass_2dm(data) {
      return [
        {
          'positive': data.v2d_mean > 0,
          'negative': data.v2d_mean < 0,
          'neutral': data.v2d_mean == 0,
        }
      ];
    },
    priceClass_3dm(data) {
      return [
        {
          'positive': data.v3d_mean > 0,
          'negative': data.v3d_mean < 0,
          'neutral': data.v3d_mean == 0,
        }
      ];
    },
    priceClass_5dm(data) {
      return [
        {
          'positive': data.v5d_mean > 0,
          'negative': data.v5d_mean < 0,
          'neutral': data.v5d_mean == 0,
        }
      ];
    },        
    priceClass_zairyo(data) {
      return [
        {
          'positive': data.zairyo_m > 0,
          'negative': data.zairyo_m < 0,
          'neutral': data.zairyo_m == 0,
        }
      ];
    },       
    priceClass_teku(data) {
      return [
        {
          'positive': data.teku_m > 0,
          'negative': data.teku_m < 0,
          'neutral': data.teku_m == 0,
        }
      ];
    },   
    priceClass_tokusyuu(data) {
      return [
        {
          'positive': data.tokusyuu_m > 0,
          'negative': data.tokusyuu_m < 0,
          'neutral': data.tokusyuu_m == 0,
        }
      ];
    },   
    priceClass_syuusei(data) {
      return [
        {
          'positive': data.syuusei_m > 0,
          'negative': data.syuusei_m < 0,
          'neutral': data.syuusei_m == 0,
        }
      ];
    },   
    priceClass_kaijijohou(data) {
      return [
        {
          'positive': data.kaijijohou_m > 0,
          'negative': data.kaijijohou_m < 0,
          'neutral': data.kaijijohou_m == 0,
        }
      ];
    },  
    priceClass_kessan(data) {
      return [
        {
          'positive': data.kessan_m > 0,
          'negative': data.kessan_m < 0,
          'neutral': data.kessan_m == 0,
        }
      ];
    },   
    priceClass_chuumoku(data) {
      return [
        {
          'positive': data.chuumoku_m > 0,
          'negative': data.chuumoku_m < 0,
          'neutral': data.chuumoku_m == 0,
        }
      ];
    },   
    priceClass_sikyou(data) {
      return [
        {
          'positive': data.sikyou_m > 0,
          'negative': data.sikyou_m < 0,
          'neutral': data.sikyou_m == 0,
        }
      ];
    },   
    priceClass_gopa(data) {
      return [
        {
          'positive': data.gopa_m > 0,
          'negative': data.gopa_m < 0,
          'neutral': data.gopa_m == 0,
        }
      ];
    },  
    priceClass_other(data) {
      return [
        {
          'positive': data.other_m > 0,
          'negative': data.other_m < 0,
          'neutral': data.other_m == 0,
        }
      ];
    },
    getChipClass(idx){
      return [
        {'a-active':this.active1===idx},
      ]
    },
    getChipTextClass(idx){
      return [
        {'text-active':this.active1===idx},
      ]
    }    
	} 
}
</script>

<style scoped lang="scss">
  @import '../assets/prod/styles/prices.scss';
  .accordion-r {
    margin-bottom: 1rem
  }
  .chip-r {
    margin: 2px
  }
  a {
    color: #515c66;
    // color: #ffffff;
  }
  a:hover {
    color: #4291dc;
    // text-decoration:underline;
  }
  .a-active {
    background-color: rgba(47, 142, 229, 0.4);
  }
  .text-active {
    color: #ffffff;
  }
</style>